export default function useMenu(menuKey = 'menu') {
  const { config } = useCms()
  const route = useRoute()
  const menu = useState(menuKey, () => ({
    isOpen: false,
    subMenuId: '',
  }))

  /**
   * toggles the mobile menu and resets
   * any open subMenu
   */
  function toggleMenu() {
    menu.value = {
      isOpen: !menu.value.isOpen,
      subMenuId: activeTrail.value?.[0] ?? '',
    }
  }

  function hideMenu() {
    menu.value = {
      isOpen: false,
      subMenuId: '',
    }
  }

  function toggleSubMenu(menuId: string) {
    menu.value = {
      isOpen: menu.value.isOpen,
      subMenuId: menuId ?? '',
    }
  }

  // TODO: type this function
  function findActiveTrail(menu, url, trail = []) {
    for (const item of menu) {
      const newTrail = [...trail, item.id]

      if (item.url === url) {
        return newTrail
      }

      if (item.children && item.children.length > 0) {
        const childTrail = findActiveTrail(item.children, url, newTrail)
        if (childTrail) {
          return childTrail
        }
      }
    }

    return null
  }

  const menuItems = computed(() => config.value?.headerMenu)
  // TODO: check if toggleMenu() can be removed and check subMenuId from toggle()
  const isOpen = computed({
    get: () => menu.value.isOpen,
    set: (value) => {
      menu.value = {
        ...menu.value,
        isOpen: value,
      }
    },
  })

  const activeSubMenu = computed(() => menu.value.subMenuId)
  const activeTrail = computed(() =>
    findActiveTrail(menuItems.value, route.path)
  )

  return {
    menu,
    menuItems,
    activeTrail,
    activeSubMenu,
    toggleMenu,
    hideMenu,
    toggleSubMenu,
    isOpen,
  }
}
