<template>
  <div class="contents">
    <NuxtLink
      :id="id"
      :to="url"
      class="text-on-header item-center flex items-center py-3 pl-14 pr-8 text-sm hover:bg-black/20 lg:px-5 lg:py-5"
      :class="{
        'pl-8': indentLevel1 || indentLevel2,
      }"
      active-class="bg-black/20 !text-primary"
    >
      <MenuItemIcon v-if="indentLevel2" :icon="icon" />
      <span v-text="label" />
    </NuxtLink>

    <MenuItemLevel3
      v-for="child in children"
      :key="child.id"
      v-bind="child"
      :indent-level1="indentLevel1"
      :indent-level2="indentLevel2"
      :indent-level3="!!hasIcons"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  indentLevel1: boolean
  indentLevel2: boolean
  id: string
  url: string
  label: string
  icon: string
  children?: {
    id: string
    url: string
    label: string
    icon: string
  }[]
}

const props = defineProps<Props>()
const hasIcons = computed(() =>
  props.children?.some(({ icon }: { icon: string }) => icon)
)
</script>

<script lang="ts">
export default {
  name: 'MenuItemLevel2',
}
</script>
