<template>
  <header id="header">
    <PageContainer
      :default-theme="false"
      :default-vertical-spacing="false"
      :grow="false"
      class="bg-header bleed-header skin:bleed-none bleed-bg flex h-[var(--header-height)] items-center justify-between"
    >
      <PageHeaderNavigationToggle v-model="isOpen" class="shrink-0" />

      <div
        class="flex flex-grow items-center justify-center pr-10 lg:justify-start lg:pr-0"
      >
        <PageLogo class="h-8 lg:h-12" />
      </div>

      <PageNavigation :open="isOpen" />

      <Builder v-if="backendToken" />
    </PageContainer>
  </header>
</template>

<script setup lang="ts">
const { isOpen } = useMenu()
const { backendToken } = useBackendToken()

defineOptions({
  name: 'PageHeader',
})
</script>

<i18n>
de:
  btn:
    themeRoller: "Design ändern"
</i18n>

<style>
:root {
  --header-height: 3.5rem;
  scroll-padding-top: var(--header-height);
}

@media (min-width: 1024px) {
  :root {
    --header-height: 5rem;
  }
}
</style>
