<template>
  <div class="h-auto w-8 fill-current pl-1.5 pr-2" v-html="icon" />
</template>

<script lang="ts" setup>
interface Props {
  icon?: string
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'MenuItemIcon',
}
</script>
