<template>
  <PageContainer
    id="footer"
    as="footer"
    :default-theme="false"
    :default-vertical-spacing="false"
    :grow="false"
    class="bg-footer bleed-footer bleed-bg text-on-footer"
  >
    <div
      class="flex flex-wrap justify-center space-y-8 py-5 sm:justify-between sm:space-y-0"
    >
      <div class="flex w-full justify-center space-x-3 sm:w-auto">
        <SocialLink
          v-for="socialLink in config?.socialLinks"
          :key="socialLink"
          :link="socialLink"
          class="transition-opacity duration-150 hover:opacity-80"
        />
      </div>
      <ul
        class="divide-on-footer flex flex-wrap items-center justify-self-center text-xs [&>li]:px-2.5 [&>li]:leading-none"
      >
        <li v-for="{ label, url } in config?.footerMenu" :key="label">
          <NuxtLink :to="url" :title="label" class="hover:underline">
            {{ label }}
          </NuxtLink>
        </li>
        <li>
          <button
            class="block text-xs hover:underline"
            type="button"
            @click="toggleModal({ settings: true, initial: false })"
            v-text="t('links.consent')"
          />
        </li>
      </ul>

      <div class="flex w-full justify-center space-x-3 sm:w-auto">
        <NuxtLink
          :to="EXTERNAL_URLS.KINOHELD"
          :title="t('links.kinoheld')"
          class="transition-opacity duration-150 hover:opacity-80"
        >
          <InlineSvg
            :type="INLINE_SVG_TYPE.LOGO"
            :name="PORTAL_LOGO.POWERED_BY_KINOHELD"
            class="h-8 w-auto fill-current"
          />
        </NuxtLink>
      </div>
    </div>
  </PageContainer>
</template>

<script setup lang="ts">
const { config } = useCms()
const { t } = useI18n()
const { toggleModal } = useConsentManager()
</script>

<script lang="ts">
export default {
  name: 'PageFooter',
}
</script>

<i18n>
de:
  links:
    consent: "Cookie Einstellungen"
    privacy: "Datenschutz"
    imprint: "Impressum"
    kinoheld: "kinoheld"
</i18n>
