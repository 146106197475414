<template>
  <div
    class="text-on-header group lg:relative lg:flex lg:h-20 lg:flex-wrap lg:items-center"
  >
    <component
      :is="!children.length ? NuxtLink : 'button'"
      :to="url"
      class="flex w-full items-start justify-start py-3 pr-4 outline-none hover:bg-black/20 lg:px-0 lg:py-0 lg:pl-4 lg:hover:bg-transparent"
      :class="{
        'pl-2': indentLevel1,
        'pl-10': !indentLevel1,
      }"
      @click="toggleMenu"
      @mouseenter="hideOpenMenus"
    >
      <MenuItemIcon
        v-if="indentLevel1 && icon"
        class="lg:hidden"
        :icon="icon"
      />

      <span
        class="min-w-fit border-b-2 border-transparent font-bold lg:font-normal"
        :class="{
          '!border-primary': activeTrail?.[0] === id,
        }"
        v-text="label"
      />
      <InlineSvg
        v-if="children.length"
        :name="SVG_ICON.CHEVRON_DOWN"
        class="ml-auto h-6 w-6 flex-shrink-0 fill-current lg:group-hover:rotate-180"
        :class="{ 'rotate-180': isOpen }"
      />
    </component>

    <div
      class="lg:bg-header-dropdown hidden lg:absolute lg:top-full lg:z-10 lg:w-screen lg:max-w-max lg:shadow-lg"
      :class="{
        'right-0': right > width,
        '!block': isOpen,
        'lg:group-hover:block': !isNavigating,
      }"
    >
      <div
        ref="target"
        class="scrollbar-themed max-h-[calc(100vh-20rem)] w-full overflow-y-auto"
      >
        <MenuItemLevel2
          v-for="child in children"
          :key="child.id"
          v-bind="child"
          :indent-level1="indentLevel1"
          :indent-level2="hasIcons"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { promiseTimeout } from '@vueuse/core'

const { toggleSubMenu, activeSubMenu, activeTrail } = useMenu()
const route = useRoute()
const isOpen = ref(false)
const isNavigating = ref(false)

interface Props {
  id: string
  url: string
  label: string
  icon: string
  children: {
    id: string
    url: string
    label: string
    icon: string
    children: {
      id: string
      url: string
      label: string
      icon: string
    }[]
  }[]
  indentLevel1: boolean
}

const NuxtLink = resolveComponent('NuxtLink')
const props = defineProps<Props>()
const hasIcons = computed(() =>
  props.children.some(({ icon }: { icon: string }) => icon)
)

const target = ref(null)
const { width } = useWindowSize()
const { right } = useElementBounding(target)

function toggleMenu() {
  if (!isOpen.value) {
    toggleSubMenu(props.id)
    isOpen.value = true
  } else {
    toggleSubMenu('')
    isOpen.value = false
  }
}

function hideOpenMenus() {
  toggleSubMenu('')
}

watch(activeSubMenu, () => {
  if (activeSubMenu.value !== props.id && isOpen.value) {
    isOpen.value = false
  }

  if (activeSubMenu.value === props.id) {
    isOpen.value = true
  }
})

watch(
  () => route.path,
  async () => {
    isNavigating.value = true
    isOpen.value = false
    await promiseTimeout(50)
    isNavigating.value = false
  }
)
</script>

<script lang="ts">
export default {
  name: 'MenuItemLevel1',
}
</script>
