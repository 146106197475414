<template>
  <NuxtLink
    :id="id"
    :to="url"
    class="text-on-header flex py-3 pl-20 pr-8 text-sm hover:bg-black/20 lg:py-5 lg:pl-8"
    :class="{
      'pl-14': indentLevel1 || indentLevel2 || indentLevel1,
    }"
    active-class="bg-black/20 !text-primary"
  >
    <MenuItemIcon v-if="indentLevel3" :icon="icon" />
    <span v-text="label" />
  </NuxtLink>
</template>

<script setup lang="ts">
interface Props {
  indentLevel1: boolean
  indentLevel2: boolean
  indentLevel3: boolean
  id: string
  url: string
  label: string
  icon: string
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'MenuItemLevel3',
}
</script>
